<template>
  <Dialog :visible="containerVisible" :style="{width: '600px'}" :header="$t('partner.dialog.form_header')" :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="field grid">
      <label for="partnerType" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.partnerType')}}</label>
      <FormField :message="errors.partnerType" class="col-12 md:col-10">
        <Dropdown ref="partnerType" id="partnerType"
          v-model="currentEntity.partnerType"
          :options="partnerTypes"
          optionLabel="label"
          optionValue="value"
          :placeholder="$t('partner.select_parter_type')"
          :required="true"
          @change="onChangePartnerType($event)">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
        </Dropdown>
      </FormField>
    </div>
    <div class="field grid">
      <label for="code" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.code')}}</label>
      <form-field :message="errors.code" class="col-12 md:col-10">
        <InputText ref="code" id="code" v-model.trim="currentEntity.code"  autofocus :required="true" />
      </form-field>
      <!--
      <div class="col-12 md:col-10">
        <InputText ref="code" id="code" v-model.trim="currentEntity.code" :required="true" autofocus />
        <small class="p-invalid" v-if="submitted && !currentEntity.code">{{codeError}}</small>
      </div>
      -->
    </div>
    <div class="field grid">
      <label for="name" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.name')}}</label>
      <form-field :message="errors.name" class="col-12 md:col-10">
        <InputText ref="name" id="name" v-model.trim="currentEntity.name" :required="true" autofocus/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="country" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.country')}}</label>
      <form-field class="col-12 md:col-10"
        :message="errors.country">
        <InputText ref="country" id="country" v-model.trim="currentEntity.country" :required="true" autofocus type="text"/>
        </form-field>
    </div>
    <div class="field grid">
      <label for="state" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.state')}}</label>
      <form-field class="col-12 md:col-10"
        :message="errors.state">
        <AutoCompleteValue ref="state" v-model="currentEntity.state" :options="usStates" :required="true"/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="address" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.address')}}</label>
      <div class="col-12 md:col-10">
        <Textarea id="address" v-model="currentEntity.address" required="false" rows="2" cols="20" />
      </div>
    </div>
    <div class="field grid">
      <label for="contactName" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.contactName')}}</label>
      <form-field :message="errors.contactName" class="col-12 md:col-10">
        <InputText ref="contactName" id="contactName" v-model.trim="currentEntity.contactName" :required="true" autofocus type="text"/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="contactPhone" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.contactPhone')}}</label>
      <form-field :message="errors.contactPhone" class="col-12 md:col-10">
        <InputText ref="contactPhone" id="contactPhone" v-model.trim="currentEntity.contactPhone" :required="true" autofocus type="text"/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="contactEmail" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('partner.contactEmail')}}</label>
      <form-field :message="errors.contactEmail" class="col-12 md:col-10">
        <InputText ref="contactEmail" id="contactEmail" v-model.trim="currentEntity.contactEmail" required="false" autofocus :class="{'p-invalid': invalidContactEmail}" type="text"/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="description" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.description')}}</label>
      <div class="col-12 md:col-10">
        <Textarea id="description" v-model="currentEntity.description" required="false" rows="3" cols="20" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
import ConfigService from '@/service/ConfigService'
import GeoInfoService from '@/service/GeoInfoService'
import AutoCompleteValue from '@/components/form/AutoCompleteValue'
import FormField from '@/components/FormField'
import form from '@/mixins/form'
//import { useForm, useField } from 'vee-validate';
export default {
  components: {AutoCompleteValue, FormField},
  name:'form-partner',
  mixins: [form],
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {  }
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel','save','hide'],
  data() {
    return {
      submitted: false,
      currentEntity: this.entity,
      containerVisible: this.visible,
      partnerTypes: [],
      usStates: [],
      vnProvinces: [],
      selectedPartnerType: null,
      errors: {},
    }
  },
  methods: {
    onChangePartnerType() {
      //this.currentEntity.partnerType = event.value.value;
    },
    onCancel() {
      this.containerVisible = false;
      this.currentEntity = {};
      this.$emit('cancel');
    },
    onSave() {
      if(!this.submitted) {
        this.errors = this.validate(this.currentEntity, 'partner');
        if(Object.keys(this.errors).length == 0) {
          this.submitted = true;
          this.$emit('save', this.currentEntity);
        }
      }
    },
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  },
  mounted() {
    GeoInfoService.loadFirstLevel(['us','vn']).then(res => {
      this.usStates = res['us'];
      this.vnProvinces = res['vn'];
    });
    this.partnerTypes = ConfigService.getPartnerTypes().map((type) => {
      return {
        label: this.$t('configs.partner_type.' + type),
        value: type
      }
    });
    this.selectedPartnerType = ConfigService.getSelectedItem(this.partnerTypes, this.currentEntity, "partnerType");
  },
  updated() {
    this.currentEntity = this.entity;
    this.selectedPartnerType = ConfigService.getSelectedItem(this.partnerTypes, this.currentEntity, "partnerType");
    this.containerVisible = this.visible;
  },
}
</script>
